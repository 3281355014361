<template>
  <el-button type="primary" @click="onopen">
    {{ componentData.title ? componentData.title : "超链接按钮" }}
  </el-button>
</template>

<script>
export default {
  registerName: "lp-abutton",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    onopen() {
      window.open(this.componentData.href, "_blank");
    },
  },
};
</script>

<template>
  <div>
    <div class="lp-button" :style="computedStyle" @click="toWechat"></div>
    <el-dialog title="微信" :visible.sync="dialogVisible" width="80%">
      <div class="flex-c">
        <el-image
          style="width: 200px; height: 200px"
          :src="ruleForm.weixinpic.length > 0 ? ruleForm.weixinpic[0].url : ''"
        >
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  registerName: "lp-weixin",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        action: "打开微信",
        weixin: "",
        pic: [],
        weixinpic: [],
      },
    };
  },
  computed: {
    computedStyle() {
      const newobj = {};
      if (this.componentData.pic && this.componentData.pic[0].url) {
        newobj["background-image"] = `url(${
          this.componentData.pic[0].url || ""
        })`;
      }
      Object.keys(this.componentData).map((key) => {
        newobj[key] = this.componentData[key] + "px";
      });
      return newobj;
    },
  },
  mounted() {
    if (this.componentData) {
      this.ruleForm = {
        ...this.ruleForm,
        ...this.componentData,
      };
    }
  },
  methods: {
    toWechat() {
      if (this.ruleForm.action === "打开微信") {
        this.$emit("toWechat", this.ruleForm.weixin);
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lp-button {
  width: 60px !important;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  background-color: #0003;
  position: relative;
  z-index: 9999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.flex-c {
  display: flex;
  justify-content: center;
}
</style>

import Vue from 'vue';
import App from './App.vue';
import Element from 'element-ui';
import './theme/element-variables.scss';
import './theme/index.css';
import VueClipboard from 'vue-clipboard2';
Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(Element);
new Vue({
  render: h => h(App)
}).$mount('#app');

<template>
  <video v-if="input" controls width="100%">
    <source :src="input" type="video/mp4" />
    <a :href="input"></a>
  </video>
  <div v-else style="width: 100%; height: 200px" class="flex-center">视频</div>
</template>

<script>
export default {
  registerName: "lp-vide",
  props: {
    componentData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    componentData: {
      deep: true,
      immediate: true,
      handler(nv) {
        if (Array.isArray(nv) && nv.length > 0) {
          this.input = this.componentData[0].url;
        } else {
          this.input = "";
        }
      },
    },
  },
};
</script>

import { render, staticRenderFns } from "./lp-banner.vue?vue&type=template&id=50a5a416"
import script from "./lp-banner.vue?vue&type=script&lang=js"
export * from "./lp-banner.vue?vue&type=script&lang=js"
import style0 from "./lp-banner.vue?vue&type=style&index=0&id=50a5a416&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__vr6wk44uaskdifpiucojfetjim/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div style="padding: 10px 10px">
    {{ qfrom.input || "请输入标题" }}
  </div>
</template>

<script>
export default {
  registerName: "lp-input",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      qfrom: {
        input: "",
      },
    };
  },
  mounted() {
    if (this.componentData) {
      this.qfrom = {
        input: "",
        ...this.componentData,
      };
    }
  },
};
</script>

<template>
  <div class="lp-image">
    <div class="block">
      <el-carousel trigger="click" height="150px">
        <div v-if="componentData.length == 0" class="image-up">
          <div class="el-upload__text">暂无内容</div>
        </div>
        <el-carousel-item
          v-for="(item, index) in componentData"
          v-else
          :key="index"
        >
          <el-image
            class="upload-image"
            :src="item.url"
            @click="onopen(item.openUrl)"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  registerName: "lp-banner",
  props: {
    componentData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    onopen(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scope>
.lp-image {
  .upload-image {
    width: 100%;
    height: 100%;
  }
}

.default-image {
  width: 100%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #909399;
}

.image-up {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.el-upload-dragger {
  width: 100% !important;
  height: 100%;
}

.el-image,
image {
  font-size: 0px;
  display: block;
}

.default-image {
  font-size: 20px;
}
</style>

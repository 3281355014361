<template>
  <div class="lp-phone">
    <el-form>
      <el-form-item label="手机号:">
        <el-input v-model="qfrom.phone" placeholder="请填写手机号"></el-input>
      </el-form-item>

      <el-form-item label="验证码:">
        <el-input v-model="qfrom.code" placeholder="请输入验证码">
          <span slot="append" @click="onDowTmime">{{
            time ? time : "验证码"
          }}</span>
        </el-input>
      </el-form-item>

      <el-form-item v-if="!isForm" label=" ">
        <div style="display: flex; justify-content: flex-end; margin-top: 10px">
          <el-button
            style="width: 20%"
            type="primary"
            @click="onSave"
            :disabled="isValidate.state && isValidate.phone === qfrom.phone"
          >
            提交
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "../config/api";
export default {
  registerName: "lp-phone",
  props: {
    isForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      time: 0,
      isValidate: {
        state: false,
        phone: "",
      },
      qfrom: {
        phone: "",
        code: "",
      },
    };
  },
  methods: {
    async onValidate() {
      if (!this.isForm) {
        return (
          this.isValidate.state && this.isValidate.phone === this.qfrom.phone
        );
      } else {
        await this.onSave();
        return (
          this.isValidate.state && this.isValidate.phone === this.qfrom.phone
        );
      }
    },
    onGetJson() {
      return {
        ...this.qfrom,
      };
    },
    onDowTmime() {
      if (!/^1[3456789]\d{9}$/.test(this.qfrom.phone)) {
        this.$message.error("请正确填写11位手机号");
        return;
      }
      if (this.time > 1) {
        return;
      }
      this.time = 60;
      const onTask = () => {
        const timeObj = setTimeout(() => {
          clearTimeout(timeObj);
          this.time--;
          if (this.time >= 1) {
            onTask();
          }
        }, 1000);
      };
      onTask();
      this.onSms();
    },
    onSms() {
      if (this.isValidate.state && this.isValidate.phone === this.qfrom.phone) {
        this.$message.error("请勿重复发送");
        return;
      }
      const apiurl = api + "/api/lp/phone/code";
      axios
        .post(apiurl, {
          phone: this.qfrom.phone,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("发送成功");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error("失败");
        });
    },
    onSave() {
      return new Promise((resolve) => {
        console.log("save");
        if (!/^1[3456789]\d{9}$/.test(this.qfrom.phone)) {
          this.$message.error("请正确填写11位手机号");
          resolve(false);
        } else {
          const apiurl = api + "/api/lp/phone/verify";
          axios
            .post(apiurl, {
              code: this.qfrom.code,
              phone: this.qfrom.phone,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.isValidate.state = true;
                this.isValidate.phone = this.qfrom.phone;
                if (!this.isForm) {
                  this.$message.success("提交成功");
                }
                resolve(true);
              } else {
                this.$message.error(res.data.msg);
                resolve(false);
              }
            })
            .catch(() => {
              this.$message.error("提交失败");
              resolve(false);
            });
        }
      });
    },
  },
};
</script>

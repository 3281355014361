<template>
  <div>
    <div class="lp-button" :style="computedStyle" @click="toWechat"></div>
    <el-dialog title="微信" :visible.sync="dialogVisible" width="80%">
      <div class="flex-c">
        <el-image
          style="width: 200px; height: 200px"
          :src="
            componentData.weixinpic && componentData.weixinpic.length > 0
              ? componentData.weixinpic[0].url
              : ''
          "
        >
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  registerName: "lp-button",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {
          value: {},
        };
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        action: "打开微信",
        weixin: "",
        pic: [],
        weixinpic: [],
      },
    };
  },
  mounted() {
    if (this.componentData) {
      this.ruleForm = {
        ...this.ruleForm,
        ...this.componentData,
      };
    }
  },
  computed: {
    computedStyle() {
      const newobj = {};
      Object.keys(this.componentData).map((key) => {
        newobj[key] = this.componentData[key] + "px";
      });

      newobj.transform =
        "translate3d(" + newobj.left + ", " + newobj.top + ", 0)";

      newobj.left = "";
      newobj.top = "";
      newobj.width = "100%";
      console.log(newobj);
      return newobj;
    },
  },
  methods: {
    toWechat() {
      console.log(this.componentData);
      if (this.componentData.action == "跳转地址") {
        window.open(this.componentData.openUrl, "_blank");
      } else if (this.componentData.action == "打开微信") {
        this.$emit("toWechat", this.componentData.weixin);
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lp-button {
  position: absolute;
  z-index: 99999;
}
</style>

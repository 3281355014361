<template>
  <div>
    <div class="lp-contact">
      <div class="lp-contact-img" @click="onGo">
        <img src="./top.png" />
      </div>
      <div class="lp-contact-text" @click="toWechat">在线联系</div>
    </div>
    <el-dialog title="微信" :visible.sync="dialogVisible" width="80%">
      <div class="flex-c">
        <el-image
          style="width: 200px; height: 200px"
          :src="
            componentData.weixinpic && componentData.weixinpic.length > 0
              ? componentData.weixinpic[0].url
              : ''
          "
        >
        </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  registerName: "lp-contact",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    onGo() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    toWechat() {
      console.log(this.componentData);
      if (this.componentData.action == "跳转地址") {
        window.open(this.componentData.openUrl, "_blank");
      } else if (this.componentData.action == "打开微信") {
        this.$emit("toWechat", this.componentData.weixin);
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style scoped>
.lp-contact {
  width: 100%;
  height: 50px !important;
  background: #f56c6c;
  color: #fff;
  display: flex;
  position: fixed;
  bottom: 0px;
}

.lp-contact .lp-contact-img {
  width: 50px;
  flex: 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-contact .lp-contact-img img {
  width: 28px;
  position: relative;
}

.lp-contact .lp-contact-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #fff;
  font-size: 20px;
  letter-spacing: 3px;
  height: 50px;
}
</style>

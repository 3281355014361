<template>
  <div class="app-warp">
    <component
      :is="com.registerName"
      v-for="com in list"
      :key="com.id"
      :component-data="com.value"
      @toWechat="toWechat"
    ></component>
  </div>
</template>

<script>
import axios from "axios";
import { getQueryString, uuid } from "./uilts/uilts";
import { api } from "./config/api";
import lpBanner from "./components/lp-banner.vue";
import lpButton from "./components/lp-button.vue";
import lpContact from "./components/lp-contact.vue";
import lpImage from "./components/lp-image.vue";
import lpPhone from "./components/lp-phone.vue";
import lpFrom from "./components/lp-from.vue";
import lpWeixin from "./components/lp-weixin.vue";
import lpVide from "./components/lp-vide.vue";
import lpInput from "./components/lp-input.vue";
import lpAbutton from "./components/lp-abutton.vue";
export default {
  name: "App",
  components: {
    lpBanner,
    lpButton,
    lpContact,
    lpImage,
    lpPhone,
    lpVide,
    lpWeixin,
    lpFrom,
    lpInput,
    lpAbutton,
  },
  data() {
    return {
      list: [],
      wechat: {},
      url: "",
      phoneNumber: "",
      tipContent: "",
      showModal: false,
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    onNewJson(jsonStr) {
      const newlist = JSON.parse(jsonStr);
      newlist.map((com) => {
        if (com.registerName === "lp-from") {
          if (com.value.listfrom) {
            com.value.listfrom.map((item) => {
              if (
                item.type === "radio" &&
                !item.value &&
                item.list.length > 0
              ) {
                item.value = item.list[0].name;
              }
            });
          }
        }
      });
      return newlist;
    },
    onNewApi() {
      const href = location.href;
      const isParam = href.includes("?"); // 判断是否存在参数
      let totalParam = "";
      let url = api + "/api/lp/landpage/" + getQueryString("id");
      if (isParam) {
        totalParam = href.substring(href.indexOf("?"), href.length);
        url += totalParam;
      }
      return url;
    },
    onQuery() {
      axios.get(this.onNewApi()).then((res) => {
        if (res.data.code != 200) {
          this.$message.error(res.data.msg);
          return;
        }
        const data = res.data.data;
        // data.is_audit = 0;
        const landpage = data.landpage;
        const jsonStr = data.is_audit
          ? landpage.page_audit_html
          : landpage.page_real_html;
        this.list = this.onNewJson(jsonStr);
        this.wechat = data.wechat;
      });
    },
    toWechat(wechat_account) {
      this.$copyText(wechat_account || this.wechat.wechat_account).then(() => {
        const url = api + "/api/lp/wechat/copy";
        const isParam = location.href.includes("?"); // 判断是否存在参数
        let copyParam = {};
        if (isParam) {
          copyParam = {
            ad_id: getQueryString("ad_id"),
            biz_no: uuid(),
            callback_ext_info: getQueryString("callback_param"),
            group_id: getQueryString("group_id"),
            landpage_id: parseInt(getQueryString("id")),
            principal_id: getQueryString("principal_id"),
            request_id: getQueryString("request_id"),
            wechat_id: this.wechat.id,
          };
        }
        axios.post(url, copyParam).then((res) => {
          if (res.data.code != 200) {
            this.$message.error("服务异常,重新再试!");
            return;
          }
        });
        window.location.href = "weixin://";
      });
    },
  },
};
</script>

<style lang="scss">
.app-warp {
  padding-bottom: 100px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.el-form-item {
  margin-bottom: 10px !important;
}

.el-form {
  padding: 0px 10px;
}

.reset-from {
  .el-form {
    padding: 0px 0px !important;
  }
}

.flex-c {
  display: flex;
  justify-content: center;
}
</style>

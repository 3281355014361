<template>
  <div class="lp-image">
    <el-image class="upload-image" :src="componentData.uploadPic"></el-image>
  </div>
</template>

<script>
export default {
  registerName: "lp-image",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onopen() {
      window.open(this.componentData.openUrl, "_blank");
    },
  },
};
</script>
